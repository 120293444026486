import styled from 'styled-components';

const PageContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: ${props => props.alignment || 'center'};
  justify-content: ${props => props.justifyContent};
  max-width: ${props => props.maxWidth};
  align-content: ${props => props.alignContent};
`;

export default PageContent;
