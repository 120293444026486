import styled from 'styled-components';

const Header = styled.header`
  padding : ${props => props.padding || '1em'};
  height: 2.5rem;
  background: ${props => props.theme.primaryColor};
  color: ${props => props.fontColor || 'white'};
  width: 100vw;
  position: relative;
  top: 0px;
  align-items: center;
  display: flex;
`;

export default Header;
