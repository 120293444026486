import { useState } from "react";
import { Box, Button, ContentArea } from "../Layout";
import { Label } from "../Common/";
import styled from "styled-components";
import writeEmailInformation from "../../services/firebaseDataWriteService";

const Form = styled.form`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
`;

const Input = styled.input`
  padding: 0.5em 1em;
  /* margin: 10px 5px; */
`;

const FormField = (props) => (
  <Box {...props} justifyContent="center">
    <Label>{props.displayName}</Label>
    <Input
      {...props}
      onChange={props.onChange}
      onClick={props.onClick}
      type={props.type || "text"}
    />
  </Box>
);

const EmailCaptureForm = (props) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    let date = new Date()
      .toISOString()
      .replace(/[^0-9]/g, "")
      .slice(0, -3);
    writeEmailInformation(date, name, email);
    setSubmitted(true);
  };

  return (
    <Box direction="column">
      <ContentArea
        background="#fff"
        rounded
        border
        padding="2.5em"
        flexDirection="row"
      >
        <Form flexDirection="column">
          <FormField
            required
            displayName="Name"
            direction="column"
            autoFocus
            type="text"
            padding="0.5em 1em"
            onChange={(e) => setName(e.target.value)}
          />
          <FormField
            required  
            displayName="Email"
            direction="column"
            type="email"
            padding="0.5em 1em"
            onChange={(e) => setEmail(e.target.value)}
          />
          {!submitted && (
            <Box justifyContent="center">
              <Button maxWidth="120px" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          )}
        </Form>
        {submitted && (
          <Box padding="1.5em 1em">
            {" "}
            Thanks for signing up! Be on the look out for updates about upcoming
            events!
          </Box>
        )}
      </ContentArea>
    </Box>
  );
};

export default EmailCaptureForm;
