import styled from 'styled-components';


const Image = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 480px) {
      max-width: 370px;
      height: auto;
  }
`;

export default Image;
