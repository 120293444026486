import { ContentArea, Box} from "../../Layout";
import { ThemeProvider } from "styled-components";
import PageContent from "../../PageContent";
import EmailCaptureForm from '../../EmailCaptureForm';
import {Image} from '../../Common/';
import Logo from '../../../assets/dac-logo.png';
const invertTheme = ({ accentColor, primaryColor }) => {
  return { accentColor: primaryColor, primaryColor: accentColor };
};




const Home = () => (
  <PageContent justifyContent={'center'} maxWidth="1440px" alignContent="center" >
    <ThemeProvider theme={invertTheme}>
      <Box height="100vh" alignContent="center" maxWidth="75%">
        <Box justifyContent="center" width="100%" padding="1em">
          <Image src={Logo} width="75%" />
        </Box>
        <ContentArea>
          <p>
            Drinks Against Cancer is a non-profit organization to celebrate the people who have beaten cancer or
            are going through the fight right now. Grab a drink, a water bottle, caprisun, a glass, or a can and toast to the ones that are fighting right now.
          </p>
          <p>
            Some of the organizations that we work with include: MDA, Baylor, and St. Jude's Children's Research Hospital.
          </p>
          <p>            
            We are still in our pre-launch phase,
            but sign up to receive email updates about upcoming events, news, and more!
          </p>
      </ContentArea>
        <EmailCaptureForm />
        </Box>
    </ThemeProvider>
  </PageContent>
);

export default Home;
