const config = {
  apiKey: "AIzaSyCMh51uIv1ph2XkySBag_M1RePANF90B-o",
  authDomain: "drinksagainstcancer.firebaseapp.com",
  projectId: "drinksagainstcancer",
  storageBucket: "drinksagainstcancer.appspot.com",
  messagingSenderId: "143407332957",
  appId: "1:143407332957:web:c501299902b5905e4e97b4",
  measurementId: "G-9ZQ8VSRH7F"
};

export default config;
