import styled from 'styled-components';
const TextBox = styled.input`
  height: ${props => props.height};
  border-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.primaryColor};
  border-radius: 5px;
  margin: 1em;
  padding: 0.5em 1em;
  ${props => {
  if (props.isInvalid === true) {
    return 'background-color: #FFCCCB; border-color: red;';
  }
  else
    return '';
}}
  justify-content: ${props => props.justifyContent};
`;

export default TextBox;
