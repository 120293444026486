import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from './firebaseConfig';
/* FIREBASE */

let firebaseApp;
let isInitialized = false;
let db;

const initialize = () => {
firebaseApp = firebase.initializeApp(firebaseConfig, "DAC");
  
  db = firebaseApp.database();
  isInitialized = true;
}
const writeEmailInformation = (id, name, email) => {
  if (!isInitialized)
    initialize();
  
  db.ref(`visitors/${id}`).set({
    name,
    email
  });
};

export default writeEmailInformation;
