import styled from 'styled-components';

const ContentArea = styled.section`
  background-color: ${props => props.background};
  color: ${(props) => props.fontColor};
  width: ${(props) => props.width};
  ${(props) => (props.rounded ? "border-radius: 25px;" : "")}
  ${props => props.border ? `border: 2px solid ${props.theme.primaryColor};` : ''}
  padding: 10px 50px;
  margin: 0.25em 1em;
  display: ${props => props.display};
  flex-direction: ${props=> props.flexDirection};
`;

export default ContentArea;
