import styled from 'styled-components';
const Box = styled.div`
  display: flex;
  color: ${(props) => props.fontColor};
  flex-direction: ${(props) => props.direction || "row"};
  flex-wrap: wrap;
  flex: ${props => props.flex || '1 1 auto'};
  padding: ${(props) => props.padding || "0 0 0 0"};
  align-items: ${props => props.alignItems };
  max-width: ${props => props.maxWidth};
  min-Height: ${props => props.minHeight};
  justify-content: ${props => props.justifyContent};
  align-content: ${props => props.alignContent};
  flex-basis: ${props => props.flexBasis};
  flex-shrink: ${props => props.flexShrink};
  flex-grow: ${props => props.flexGrow};
  width: ${props => props.width};
`;

export default Box;
