import PrimaryTheme from "../../assets/theme.json";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Home } from "../Views/";

const GlobalStyle = createGlobalStyle`
body, html {
  height: 100%;
  width: 100%;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

body {
  background: ${PrimaryTheme.backgroundColor};
  display: flex;
  flex-direction: column;
}

#root {
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
`;

const App = (props) => (
  <ThemeProvider
    theme={PrimaryTheme}
    sx={{ maxWidth: "100%", justifyContent: "center" }}
  >
    {/* <PageHeader/> */}
    <BrowserRouter>
      <GlobalStyle />
      <Switch>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      {/* <PageFooter /> */}
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
