import styled from 'styled-components';
const Button = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  /* color: ${(props) => !props.inverted ? props.theme.accentColor : props.theme.primaryColor}; */
  color: ${(props) => props.fontColor || 'black'};
  height: ${props => props.height};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  ${props => {
  if (!props.border) {
    if (!props.inverted)
      return `background-color: ${props.theme.primaryColor};`
    else
      return `background-color: ${props.theme.accentColor};`
  }
  else
   return  `border: 2px solid ${ !props.inverted ? props.theme.primaryColor : props.theme.accentColor };` 
  
}}
  border-radius: 5px;
`;

export default Button;
